import "@babel/polyfill";
import $ from 'jquery';
import "whatwg-fetch";
import { TweenMax, TimelineLite } from "gsap/TweenMax";
//import "gsap/ScrollToPlugin";
import { ScrollToPlugin } from "gsap/all";
import SwipeListener from "swipe-listener";
import { ValidateEmail } from "../assets/emailValidate";
import SimpleBar from "simplebar";
import is_touch_device from './detectTouch.js';
import device from 'current-device';

const scrollPlugin = ScrollToPlugin;


let isTouch = is_touch_device();

let current = 0;
let updateTL = [];
let canChangePack = true;
let share;
let currentPack = '097';
var preloader_flag = false;

$.extend( $.easing, {
    def: 'easeOutCubic',
    easeOutCubic: function (x, t, b, c, d) {
        return c*((t=t/d-1)*t*t + 1) + b;
    },
});

if($('.progress_bar').length) {
    $('.progress_bar').animate({
          width: '95%'
        }, {
        duration: 5000,
        specialEasing: {
          width: 'easeOutCubic',
        }
    });
}

var getJuice = () => {
  return $(".product[data-start=first]").attr('data-template') || "cherry";
};

var getJuiceUrl = () => {
  return $(".product[data-start=first]").attr('data-url') || "cherry";
};

var isMoment = () => {
  return document.location.pathname.split("/").length >= 4 && document.location.pathname.split("/")[3] === "moment";
};

var productId = () => {
  return $(".product[data-start=first]").attr('data-product_id');
};

var prefix = () => {
  return ".juice--" + productId();
};

const productAnimate = () => {
  $('.product').css('display', 'none');
  
  let tl = new TimelineLite({ onStart: () => { canChangePack = false; $(`.juice__product`).removeClass('active')} });

  tl.set(".product--" + productId(), {
    visibility: "visible",
    //height: "auto",
  })
    .set(prefix() + ".juice__product", { display: "block" })
    .set(prefix() + " .juice__top", { opacity: 0 })
    .set(prefix() + " .juice__fruits", { opacity: 0 })
    .set(prefix() + ".juice__product", { opacity: 1 })
    .from(prefix() + " .pack__product--097", 0.8, {
      opacity: 0,
      /*scale: 1.1,*/
      onStart: function () { 
        if(this.target[0]) {
            this.target[0].style.transform = "";
            this.target[1].style.transform = "";
        }
      }
    })
    .from(
      prefix() + " .flower__product",
      0.6,
      { opacity: 0, /*scale: 0.8*/ },
      "-=0.6"
    )
    .from(prefix() + " .flower__small__product", 0.3, { opacity: 0 }, "-=0.5")
    .from(prefix() + " .fruit__product", 0.3, { opacity: 0 }, "-=0.3")
    .fromTo(
      ".product--" + productId() + ".product",
      1.5,
      { opacity: 0, y: 40 },
      { opacity: 1, y: 0, onComplete: function () { 
        if(this.target[0]) {
            this.target[0].style.transform = "";
        }
      } },
      "-=0.5"
    )
    .set(prefix() + " .juice__top", { opacity: 1 })
    // .from(prefix() + " .juice__line", 0.1, { width: 0 }, "+=1")
    .from(prefix() + " .juice__line", 0.1, { width: 0 }, "+=0")
    .from(prefix() + " .juice__locker", 0.5, {
      opacity: 0,
      // scale: 1.1,
      onComplete: () => {
        TweenLite.set(prefix() + " .juice__locker", {
          className: "juice__locker animated",
          onComplete: function () {
            if (this.target[0]) {
              this.target[0].removeEventListener("click", yaMoment, false);
              this.target[0].addEventListener("click", yaMoment, false);
            }
          }
        });
        //canChangePack = true;
      }
    })
    .to(prefix() + " .juice__bot--in", 0.5, { 
      opacity: 0,
      onComplete(){
        $(`${prefix()}`).addClass('active')
      }
    })

    .to(prefix() + " .juice__fruits", 0.5, { opacity: 1})
    .to(prefix() + " .juice__top_line", 0.7, {opacity: 1})
    .staggerFrom(
      prefix() + " .fruits__item",
      0.5,
      {
        display: "none",
        onStart: function () {
          let target = this.target;
          if (target.dataset.animate) {
            TweenMax.to(target, 3, {
              y: target.dataset.y,
              ease: Sine.easeInOut,
              repeat: -1,
              yoyo: true
            });
            TweenMax.to(target, 3, {
              x: target.dataset.x,
              ease: Sine.easeInOut,
              repeat: -1,
              yoyo: true
            }).progress(0.5);
          }
          
        }
      },
      0,
      "-=0.1")
    .to(prefix() + ' .juice__fruits--in', 0.8, {opacity: 1, y: 0, onComplete(){canChangePack = true;}});
  return tl;
};

var nextProduct = end => {
  // Share change
  $('.active .juice__top').removeClass('noclip');
  if (share) share.updateContent({ url: `${document.location.origin}/products/${getJuiceUrl()}/moment/` });

  // Packs change
  current = typesIds.indexOf(parseInt(productId()));
  TweenMax.set(".flower__small__product, .fruit__product, .juice__fruits, .juice__product__line", { opacity: 1 });
  TweenMax.set(".juice__top", { webkitClipPath: "" });
  TweenMax.set(".juice__bot", { opacity: 1 });
  TweenMax.set(".pack__product", { opacity: 0 });
  TweenMax.set(".pack__product--097", { opacity: 1 });

  // Thumbs scroll
  var thumbsWidth = document.querySelector('.thumbs').clientWidth;
  var thumbsItemWidth = document.querySelector('.thumbs__item').clientWidth;
  TweenMax.to('.thumbs', 0.3, { scrollLeft: (current - Math.floor(thumbsWidth / thumbsItemWidth / 2)) * thumbsItemWidth });
  $('.thumbs__item').removeClass('thumbs__item--active');
  $('.thumbs__item[data-product_id='+productId()+']').addClass('thumbs__item--active');

  var parentProduct = document.querySelector(`.product.product--${productId()}`);
  if($('.packs__item--current', parentProduct).length) {
      $('.packs__item--current', parentProduct).removeClass('packs__item--current');
  }

  $('.packs__item:first-child', parentProduct).addClass('packs__item--current');
  var el = $('.packs__item:first-child', parentProduct);
  if (el && $('.pepsico', parentProduct).length) {
    var buybtn = document.createElement("pepsico-buybtn");
    buybtn.className = "pepsico";
    if(typeof el.attr('id') !== 'undefined') {
        buybtn.id = el.dataset.id;
        buybtn.setAttribute("productid", el.dataset.id);
        buybtn.setAttribute("url", "https://marketplace.pepsico.digital/");
        parentProduct.removeChild(parentProduct.querySelector('.pepsico'));
        parentProduct.appendChild(buybtn);
    }
  }

  // Change ya moment
  if (isMoment()) {
    TweenMax.to(".moment", 1, {
      opacity: 0,
      onComplete: () => {
        TweenMax.set('.moment video', { src: !device.mobile() ? momentsVideo[getJuice()] : momentsMobileVideo[getJuice()] });
        TweenMax.set('.moment__bg img', { src: `/images/${getJuice()}/moment.jpg` });
        TweenMax.set('.moment__change', { innerHTML: momentsText[getJuice()] });
        $('.moment__text').removeClass().addClass('moment__text').addClass(getJuice()+'_moment_text');
        $('.moment__bg').removeClass().addClass('moment__bg').addClass(getJuice()+'_moment_bg');
        //$('.moment').removeClass().addClass('moment').addClass(getJuice()+'_moment_bg');
        TweenMax.to(".moment", 1, { opacity: 1, delay: 0.5 });
      },
    });
  } else {
    TweenMax.set('.moment video', { src: !device.mobile() ? momentsVideo[getJuice()] : momentsMobileVideo[getJuice()] });
    TweenMax.set('.moment__bg img', { src: `/images/${getJuice()}/moment.jpg` });
    TweenMax.set('.moment__change', { innerHTML: momentsText[getJuice()] });
    $('.moment__text').removeClass().addClass('moment__text').addClass(getJuice()+'_moment_text');
    $('.moment__bg').removeClass().addClass('moment__bg').addClass(getJuice()+'_moment_bg');
    //$('.moment').removeClass().addClass('moment').addClass(getJuice()+'_moment_bg');
  }
  // 
  document.querySelector(".arrow__prev span").innerHTML =
    typesRU[current !== 0 ? current - 1 : typesRU.length - 1];
  let prevId = typesIds[current !== 0 ? current - 1 : types.length - 1];
  $('.arrow__prev').attr('data-product_id', prevId);
  document.querySelector(".arrow__prev").href = `/products/${
    types[current !== 0 ? current - 1 : types.length - 1]
    }/`;
  document.querySelector(".arrow__next span").innerHTML =
    typesRU[current !== typesRU.length - 1 ? current + 1 : 0];
  let nextId = typesIds[current !== types.length - 1 ? current + 1 : 0];
  $('.arrow__next').attr('data-product_id', nextId);
  document.querySelector(".arrow__next").href = `/products/${
    types[current !== types.length - 1 ? current + 1 : 0]
    }/`;
  let secondsOfAnimation = 0;
  if(!end){
    if(!isTouch){
      secondsOfAnimation = 0.3;
    }
  }
  TweenMax.to(".juice", secondsOfAnimation, {
    opacity: 0,
    display: "none",
  });
  TweenMax.to(".product", secondsOfAnimation, {
    //opacity: 0,
    //visibility: "hidden",
    //height: 0,
    //marginBottom: 0,
    display: "none",
    onComplete: () => {
      if (updateTL[getJuice()]) {
        updateTL[getJuice()].restart();
      } else {
        updateTL[getJuice()] = productAnimate();
      }
      if (end) {
        updateTL[getJuice()].progress(1);
      }
    }
  });

  TweenMax.to(".product--"+getJuice(), secondsOfAnimation, {
    //opacity: 1,
    //visibility: "visible",
    //height: 'auto',
    //marginBottom: 30,
    display: "",
    onComplete: () => {
      if (updateTL[getJuice()]) {
        updateTL[getJuice()].restart();
      } else {
        updateTL[getJuice()] = productAnimate();
      }
      if (end) {
        updateTL[getJuice()].progress(1);
      }
    }
  });
};

var yaMoment = event => {
  event.preventDefault();
  history.pushState({}, "", `/products/${getJuiceUrl()}/moment/`);
  yaMomentShow();
};

var yaMomentShow = event => {
  if(getJuice()==='orange'){
    document.querySelector('.moment').classList.add('orange_moment_bg');
    document.querySelector('.moment__bg').classList.add('orange_moment_bg');
    if(window.innerWidth<=1152){
      document.querySelector('body').classList.add('orange');
    }
  }
  else{
    document.querySelector('.moment').classList.remove('orange_moment_bg');
    document.querySelector('.moment__bg').classList.remove('orange_moment_bg');
    if(window.innerWidth<=1152){
      document.querySelector('body').classList.remove('orange');
    }
  }
  
  TweenMax.to(".main", 1, {
    opacity: 0,
    onComplete: function () {
      this.target[0].style.display = "none";
    }
  });
  TweenMax.to(window, 1, { scrollTo: 0 });
  TweenMax.to(".container", 1, {
    className: "container",
  });
  let momentTl = new TimelineLite();
  momentTl
    .fromTo(
      ".moment",
      1,
      { opacity: 0, visibility: "hidden", display: "none" },
      { opacity: 1, visibility: "visible", display: "block" }
    )
    .staggerFrom(".moment p, .moment .nothing", 1, { y: 40, opacity: 0, onComplete(){
      /*let videos = document.querySelectorAll('.momentVideo');

      videos.forEach(video=>{
        video.style.width = window.innerWidth;
      })*/

    } }, 0.5);
};

var yaMomentBack = event => {
  document.querySelector('body').classList.remove('orange');
  event.preventDefault();
  TweenMax.to(".ya-share", 1, { opacity: 0 });
  TweenMax.to(".ya-share", 1, { display: "none", delay: 1 });
  TweenMax.to(".moment__share .button", 1, { display: "block", delay: 1, opacity: 1 });
  TweenMax.to(".main", 1, {
    opacity: 1,
    onComplete: function () {
      this.target[0].style.transform = "";
      document.querySelector(".container").className = "container container__glow";
    },
    onStart: function () {
      this.target[0].style.display = "";
    }
  });
  TweenMax.to(window, 1, { scrollTo: 0 });
  let momentTl = new TimelineLite();
  momentTl
    .fromTo(
      ".moment",
      1,
      { opacity: 1, visibility: "visible" },
      {
        opacity: 0,
        onComplete: function () {
          this.target[0].style.visibility = "hidden";
          this.target[0].style.display = "none";
        }
      }
    )
    .from(".moment p, .moment .nothing", 1, { y: 0, opacity: 1 }, 0.5);
  history.pushState({}, "", `/products/${getJuiceUrl()}/`);
  updateTL[getJuice()].paused(false);
};

let wheelDate = new Date();
function onWheel(e) {
  //return true; // hide moment
  e = e || window.event;
  for (let i = 0; i < e.path.length; i += 1) {
    if (e.path[i].className && (e.path[i].className.indexOf('popup') !== -1 || e.path[i].className.indexOf('modal') !== -1)) {
      return true;
    }
    if (e.path[i].className && e.path[i].className.indexOf('product product') !== -1) {
      if (e.path[i].scrollHeight > e.path[i].clientHeight) {
        return true;
      }
    }
  }

  // wheelDelta не дает возможность узнать количество пикселей
  var delta = e.deltaY || e.detail || e.wheelDelta;
  if ((new Date()) - wheelDate > 2000) {
    if (delta > 0) {
      //if (!isMoment()) {
      //  yaMoment(e);
      //}
    } else {
      if (isMoment()) {
        yaMomentBack(e);
      }
    }
    wheelDate = new Date();
  }
}

function changePack(event) {

  if (!canChangePack) return false;
  canChangePack = false;
  var el = event.currentTarget;
  //TweenMax.pauseAll(false, true, false);

  if (el.className.indexOf("current") !== -1) return false;
  console.log(el);
  var parentProduct = el.parentNode.parentNode;
  $('.packs__item--current', parentProduct).removeClass('packs__item--current');
  el.className = "packs__item packs__item--current";
  var buybtn = document.createElement("pepsico-buybtn");
  buybtn.className = "pepsico";
  if(el.dataset.id) {
      buybtn.id = el.dataset.id;
      buybtn.setAttribute("productid", el.dataset.id);
      buybtn.setAttribute("url", "https://marketplace.pepsico.digital/");
      parentProduct.removeChild(parentProduct.querySelector('.pepsico'));
      parentProduct.appendChild(buybtn);
  }
  TweenMax.to(".pack__product", 1, { opacity: 0 });
  TweenMax.to(`.pack__product${el.dataset.pack}`, 1, { opacity: 1, delay: 1 });
  if (el.dataset.pack !== "--097") {
    TweenMax.to(".flower__small__product, .fruit__product, .flower__product", 1, { opacity: 0 });
    TweenMax.to(".juice__fruits, .juice__product__line", 1, { opacity: 0 });
    TweenMax.set(".juice__top", { webkitClipPath: "none", delay: 1 });
    TweenMax.to(".juice__bot", 1, { opacity: 0 });
    TweenMax.to(".flower__product", 1, { opacity: 1, delay: 1, onComplete: () => { canChangePack = true; $(".juice__fruits").css({ opacity: 0 }) } });
    $('.active .juice__top').addClass('noclip');
  } else {
    TweenMax.to(".flower__product", 1, { opacity: 0 });
    TweenMax.to(".flower__small__product, .fruit__product", 1, { opacity: 1, delay: 1 });
    TweenMax.to(".juice__fruits, .juice__product__line", 1, { opacity: 1, delay: 1 });
    TweenMax.set(".juice__top", { webkitClipPath: "", delay: 1 });
    TweenMax.to(".juice__bot", 1, { opacity: 1, delay: 1 });
    TweenMax.to(".flower__product", 1, { opacity: 1, delay: 1, onComplete: () => { canChangePack = true; } });
    $('.active .juice__top').removeClass('noclip');
  }

  currentPack = el.dataset.pack.substr(2, el.dataset.pack.length);
  canChangePack = true;
}

function setNeedestAngle(){
  let needestAngle = window.innerWidth * window.innerHeight * 0.000167;

  let frItems = document.querySelectorAll('.juice__fruits');

  frItems.forEach(item=>{
    item.style.setProperty('--needestAngle', needestAngle+'px')
  })
}

let noScrollToResize = device.orientation;

let vh = (scroll) =>{
  if(device.type !== 'desktop'){
    let vh = window.innerHeight * 0.01;
    document.querySelector('body').style.setProperty('--vh', `${vh}px`);
  }
};

window.onload = () => {

  setNeedestAngle();
  vh();

  window.onpopstate = function(e){
    location.assign(location.href)
  }
  
  if($('.progress_bar').length) {
    $('.progress_bar').stop();
    $('.progress_bar').hide();
  }

  for(let i = 0; i < document.querySelectorAll(".ga-event").length; i++) {
    let el = document.querySelectorAll(".ga-event")[i];
    el.addEventListener("click", function () {
      if(!this.classList.contains('ga-event')) return;
      console.log(" categ: "+this.dataset.gacateg+", action: "+this.dataset.gaaction+", label: "+this.dataset.galabel);
      if(this.dataset.galabel == undefined)
          try { dataLayer.push({ event: 'GAEvent', eventCategory: this.dataset.gacateg, eventAction: this.dataset.gaaction, eventLabel: null }); } catch(e) { console.log(e); }
      else
          try { dataLayer.push({ event: 'GAEvent', eventCategory: this.dataset.gacateg, eventAction: this.dataset.gaaction, eventLabel: this.dataset.galabel }); } catch(e) { console.log(e); }
    });
  }

  // Lazy load current images
  var lazyloads = document.querySelectorAll('.juice--'+getJuice()+' .lazyload');
  for (let i = 0; i < lazyloads.length; i += 1) {
    var lazyImg = lazyloads[i];
    if (lazyImg.dataset.src) lazyImg.src = lazyImg.dataset.src;
    if (lazyImg.dataset.srcset) lazyImg.srcset = lazyImg.dataset.srcset;
  }

  var lockers = document.querySelectorAll(".juice__locker");
  for (let i = 1; i < lockers.length; i += 1) {
    lockers[i].addEventListener("click", yaMoment, false);
  }
  if (document.querySelector(".moment__back")) {
    document
      .querySelector(".moment__back")
      .addEventListener("click", yaMomentBack, false);
  }

  TweenMax.set(".juice", { display: "none" });
  if (document.location.pathname.indexOf("products") !== -1) {
    if (document.location.pathname.indexOf("moment") !== -1) {
      nextProduct(true);
      yaMomentShow();
    } else {
      nextProduct();
    }
    var thumbsItems = document.querySelectorAll(".thumbs__item");
    for (let i = 0; i < thumbsItems.length; i += 1) {
      thumbsItems[i].addEventListener(
        "click",
        event => {
          event.preventDefault();
          updateTL[getJuice()].paused(true);
          $(".product[data-start=first]").attr('data-start', null);
          $(".product.product--"+$(event.currentTarget).attr('data-product_id')).attr('data-start', 'first');
          history.pushState({}, "", event.currentTarget.href);
          nextProduct();
        },
        false
      );
    }

    if (document.querySelector(".arrow__prev")) {
      document.querySelector(".arrow__prev").addEventListener(
        "click",
        event => {
          event.preventDefault();
          updateTL[getJuice()].paused(true);
          $(".product[data-start=first]").attr('data-start', null);
          $(".product.product--"+$(event.currentTarget).attr('data-product_id')).attr('data-start', 'first');

          history.pushState({}, "", event.currentTarget.href);
          nextProduct();
        },
        false
      );
    }
    /*window.addEventListener("keydown", event => {
      if (event.key === "ArrowLeft") {
        updateTL[getJuice()].paused(true);
        history.pushState(
          {},
          "",
          `/products/${
          types[current !== 0 ? current - 1 : typesRU.length - 1]
          }/${isMoment() ? "moment/" : ""}`
        );
        nextProduct();
      } else if (event.key === "ArrowRight") {
        updateTL[getJuice()].paused(true);
        history.pushState(
          {},
          "",
          `/products/${
          types[current !== typesRU.length - 1 ? current + 1 : 0]
          }/${isMoment() ? "moment/" : ""}`
        );
        nextProduct();
      }
    });*/
    if (document.querySelector(".arrow__next")) {
      document.querySelector(".arrow__next").addEventListener(
        "click",
        event => {
          event.preventDefault();
          updateTL[getJuice()].paused(true);
          $(".product[data-start=first]").attr('data-start', null);
          $(".product.product--"+$(event.currentTarget).attr('data-product_id')).attr('data-start', 'first');
          
          history.pushState({}, "", event.currentTarget.href);
          nextProduct();
        },
        false
      );
    }
    if ('onwheel' in document) {
      // IE9+, FF17+, Ch31+
      document.querySelector('.container').addEventListener("wheel", onWheel, false);
    } else if ('onmousewheel' in document) {
      // устаревший вариант события
      document.querySelector('.container').addEventListener("mousewheel", onWheel, false);
    } else {
      // Firefox < 17
      document.querySelector('.container').addEventListener("MozMousePixelScroll", onWheel, false);
    }
    var juices = document.querySelectorAll('.juice, .moment');
    /*  
    for (let i = 0; i < juices.length; i += 1) {
      SwipeListener(juices[i], {minVertical: 1000, minHorizontal: 50});
      juices[i].addEventListener('swipe', (e) => {
        var directions = e.detail.directions;
        if (!directions.top && !directions.bottom) {
          if (directions.left) {
            updateTL[getJuice()].paused(true);
            history.pushState(
              {},
              "",
              `/products/${
              types[current !== typesRU.length - 1 ? current + 1 : 0]
              }/${isMoment() ? "moment/" : ""}`
            );
            nextProduct();
          } else if (directions.right) {
            updateTL[getJuice()].paused(true);
            history.pushState(
              {},
              "",
              `/products/${
              types[current !== 0 ? current - 1 : typesRU.length - 1]
              }/${isMoment() ? "moment/" : ""}`
            );
            nextProduct();
          }
        }
      }, {passive: true})
    }
    */

    // Swipe thumbs
    let thumbsIsScrolling = false;
    let thumbsInterval, thumbsTimeout;
    var thumbs = document.querySelector('.thumbs');
    SwipeListener(thumbs);
    document.querySelector('.thumbs').addEventListener('swipe', (e) => {
      var directions = e.detail.directions;
      var thumbsItemWidth = document.querySelector('.thumbs__item').clientWidth;
      if (thumbsInterval) {
        clearInterval(thumbsInterval);
        thumbsInterval = undefined;
      }
      if (!directions.top && !directions.bottom) {
        if (directions.left) {
          thumbsInterval = setInterval(() => {
            if (!thumbsIsScrolling) {
              clearInterval(thumbsInterval);
              thumbsInterval = undefined;
              TweenMax.to('.thumbs', 0.3, { scrollLeft: Math.ceil(thumbs.scrollLeft / thumbsItemWidth) * thumbsItemWidth });
            }
          }, 100);
        } else if (directions.right) {
          thumbsInterval = setInterval(() => {
            if (!thumbsIsScrolling) {
              clearInterval(thumbsInterval);
              thumbsInterval = undefined;
              TweenMax.to('.thumbs', 0.3, { scrollLeft: Math.floor(thumbs.scrollLeft / thumbsItemWidth) * thumbsItemWidth });
            }
          }, 100);
        }
      }
    }, false);
    document.querySelector('.thumbs').addEventListener('scroll', (e) => {
      thumbsIsScrolling = true;
      clearTimeout(thumbsTimeout);
      thumbsTimeout = setTimeout(() => { thumbsIsScrolling = false; }, 110);
    }, false);
  }

  if (window.isIndex) {
    var mainTL = new TimelineLite();
    mainTL.set(".juice", { display: "block" });
    if (!getCookie("preload") && preloader_flag === true) {
      mainTL
        .fromTo(
          ".preloader__bg",
          0.5,
          { scale: 0, opacity: 0 },
          { scale: 1, opacity: 1 }
        )
        .staggerFromTo(
          ".preloader__text__first div",
          0.5,
          { scale: 1.2, opacity: 0 },
          { scale: 1, opacity: 1 },
          0.1
        )
        .to(".preloader__text__first div", 0.5, { opacity: 0 }, "+=1")
        .staggerFromTo(
          ".preloader__text__last div",
          0.5,
          { scale: 1.2, opacity: 0 },
          { scale: 1, opacity: 1 },
          0.1
        )
        .to(
          ".preloader",
          0.5,
          {
            opacity: 0,
            onComplete: function () {
              this.target[0].style.display = "none";
            }
          },
          "+=1"
        );
      document.cookie = "preload=1; expires=0";
      document
        .querySelector(".preloader__button")
        .addEventListener("click", event => {
          event.preventDefault();
          mainTL.time(mainTL.getLabelTime("pe"));
        });
    } else {
        $('.preloader').hide();
    }
    mainTL
      .addLabel("pe")
      .set(".juice, .about", { opacity: 1 })
      .set(".header, .footer", { visibility: "visible" })
      .from(".pack", 1.5, { opacity: 0, scale: 1.1 }, '+=1')
      .from(
        ".flower",
        1.5,
        {
          opacity: 0,
          scale: 0.8,
          transformOrigin: "50% 50%",
          onComplete: function () {
            this.target[0].style.transform = "";
            this.target[0].className = "flower animated";
            document.querySelector('.container__glow--main').className = "container container__glow container__glow--main container__glow--active";
          }
        },
        "-=1.5"
      )
      .from(
        ".flower__small",
        1.5,
        {
          opacity: 0,
          scale: 1.1,
        },
        "-=1.5"
      )
      .staggerFrom(
        ".cherry__main",
        1,
        {
          opacity: 0,
          onStart: function () {
            let target = this.target;
            if (target.dataset.animate) {
              TweenMax.to(target, 3, {
                y: target.dataset.y,
                ease: Sine.easeInOut,
                repeat: -1,
                yoyo: true
              });
              TweenMax.to(target, 3, {
                x: target.dataset.x,
                ease: Sine.easeInOut,
                repeat: -1,
                yoyo: true
              }).progress(0.5);
            }
          }
        },
        0,
        "-=1"
      )
      .staggerFrom(
        ".splash__main",
        1,
        {
          opacity: 0,
          onStart: function () {
            let target = this.target;
            if (target.dataset.animate) {
              TweenMax.to(target, 3, {
                y: target.dataset.y,
                ease: Sine.easeInOut,
                repeat: -1,
                yoyo: true
              });
              TweenMax.to(target, 3, {
                x: target.dataset.x,
                ease: Sine.easeInOut,
                repeat: -1,
                yoyo: true
              }).progress(0.5);
            }
          }
        },
        0,
        "-=1"
      )
      .staggerFrom(".about > *", 1, { opacity: 0, y: 40 }, 0.1, "-=0.6")
      .from(".header", 0.8, { opacity: 0, y: -40 }, "-=0.4")
      .from(".footer", 0.8, { opacity: 0, y: 40 }, "-=0.7")
      .set("#modalAfterPreloader", { display: "block" })
      .from("#modalAfterPreloader .modal__inner", 1, { opacity: 0, y: 40 })
      .to('#refToShop', 0.5, {css: {display: 'block', opacity: 1}, delay: 1.5});
  }

  document.querySelector("#button__recall").addEventListener("click", event => {
    event.preventDefault();
    document.querySelector('.wrapp').style.overflow = "hidden";
    document.querySelector('.wrapp').style.position = "relative";
    var modalTL = new TimelineLite();
    modalTL
      .set("#recall.modal", { display: "block" })
      .fromTo("#recall .modal__overlay", 0.3, { opacity: 0 }, { opacity: 1 })
      .fromTo(
        "#recall .modal__inner",
        0.5,
        { opacity: 0, top: "60%" },
        { opacity: 1, top: "50%" },
        "-=0.3"
      );

      document.querySelector('body').classList.add('fixed');

  });

  var closeBtns = document.querySelectorAll(".modal__close");
  for (let i = 0; i < closeBtns.length; i += 1) {
    closeBtns[i].addEventListener(
      "click",
      event => {
        document.querySelector('.wrapp').style.overflow = "";
        document.querySelector('.wrapp').style.position = "";
        event.preventDefault();
        var modalTL = new TimelineLite();
        modalTL
          .fromTo(
            ".modal__inner",
            0.3,
            { opacity: 1, top: "50%" },
            { opacity: 0, top: "60%" }
          )
          .fromTo(
            ".modal__overlay",
            0.5,
            { opacity: 1 },
            { opacity: 0 },
            "-=0.3"
          )
          .set(".modal", { display: "none" });
          document.querySelector('body').classList.remove('fixed');
      },
      false
    );
  }

  let menu = false;
  document.querySelector(".menu__close").addEventListener("click", event => {
    event.preventDefault();
    if (menu) {
      TweenMax.to(".menu", 0.5, { left: "-100%" });
      document
        .querySelector(".menu__close")
        .classList.remove("menu__close--active");
      document
        .querySelector(".menu__close")
        .classList.add('ga-event');
      document.querySelector('.wrapp').style.overflow = "";
      document.querySelector('.wrapp').style.position = "";
      document.querySelector('body').classList.remove('fixed');
    } else {
      TweenMax.to(".menu", 0.5, { left: "0" });
      document
        .querySelector(".menu__close")
        .classList.add("menu__close--active");
      document
        .querySelector(".menu__close")
        .classList.remove('ga-event');
      document.querySelector('.wrapp').style.overflow = "hidden";
      document.querySelector('.wrapp').style.position = "relative";
      document.querySelector('body').classList.add('fixed');
    }
    menu = !menu;
  });

  // Custom scroll
  var productScrolls = document.querySelectorAll(".product__scrolled");
  for (let i = 0; i < productScrolls.length; i += 1) {
    productScrolls[i].querySelector(".product__scrolled-in").style.height = productScrolls[i].clientHeight + "px";
    new SimpleBar(productScrolls[i].querySelector(".product__scrolled-in"));
  }

  // Состав
  var compositions = document.querySelectorAll(".composition__input");
  for (let i = 0; i < compositions.length; i += 1) {
    compositions[i].addEventListener("change", event => {
      if (event.target.checked) {
        TweenMax.set(`.product--${getJuice()} .composition__text`, {
          height: "auto"
        });
        TweenMax.from(`.product--${getJuice()} .composition__text`, 1, {
          height: 0,
          onStart: (juice) => {
            document.querySelector(`.product--${juice} .product__scrolled-in`).style.height = "";
          },
          onStartParams: [getJuice()],
          onComplete: (juice) => {
            document.querySelector(`.product--${juice} .product__scrolled-in`).style.height = document.querySelector(`.product--${juice} .product__scrolled`).clientHeight + "px";
          },
          onCompleteParams: [getJuice()],
        });
      } else {
        TweenMax.to(`.product--${getJuice()} .composition__text`, 1, {
          height: 0,
          onStart: (juice) => {
            document.querySelector(`.product--${juice} .product__scrolled-in`).style.height = "";
          },
          onStartParams: [getJuice()],
          onComplete: (juice) => {
            document.querySelector(`.product--${juice} .product__scrolled-in`).style.height = document.querySelector(`.product--${juice} .product__scrolled`).clientHeight + "px";
          },
          onCompleteParams: [getJuice()],
        });
      }
    });
  }

  document.querySelector(".form__recall").addEventListener("submit", function (event) {
    event.preventDefault();

    var errors = {
      name: event.currentTarget[0].value < 2,
      email: !ValidateEmail(event.currentTarget[1].value),
      theme: event.currentTarget[2].value < 2,
      message: event.currentTarget[3].value < 5,
    }

    if (errors.name || errors.email || errors.theme || errors.message) {
      if (errors.name) {
        event.currentTarget[0].className = "input__errors"
      } else {
        event.currentTarget[0].className = "";
      }
      if (errors.email) {
        event.currentTarget[1].className = "input__errors"
      } else {
        event.currentTarget[1].className = "";
      }
      if (errors.theme) {
        event.currentTarget[2].className = "input__errors"
      } else {
        event.currentTarget[2].className = "";
      }
      if (errors.message) {
        event.currentTarget[3].className = "input__errors"
      } else {
        event.currentTarget[3].className = "";
      }
      return false;
    }
    fetch("/sendmail", {
      body: JSON.stringify({
        name: event.currentTarget[0].value,
        email: event.currentTarget[1].value,
        theme: event.currentTarget[2].value,
        message: event.currentTarget[3].value,
      }),
      method: "post",
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(() => {
      TweenMax.set(".form__recall", { innerHTML: "<div class=\"modal__center\">Ваше сообщение отправлено!</div>" });
    });
  }, false);

  // Change pack
  var packBtns = document.querySelectorAll(".packs__item");
  for (let i = 0; i < packBtns.length; i += 1) {
    packBtns[i].addEventListener("click", changePack, false);
  }

  share = Ya.share2('ya-share', {
    content: {
      url: `${document.location.origin}/products/${getJuice()}/moment/`
    },
    theme: {
      services: 'vkontakte,facebook,odnoklassniki',
      bare: true,
    },
    hooks: {
      onshare: function(name) {
        dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'SocialShare',
          eventAction: 'MeMomentShare',
          eventLabel: name,
          eventContext: getJuice(),
        });
      },
    },
  });

  if (document.querySelector(".moment__share .button")) {
    document.querySelector(".moment__share .button").addEventListener("click", (event) => {
      event.preventDefault();
      TweenMax.to(event.currentTarget, 1, { opacity: 0 });
      TweenMax.to(event.currentTarget, 1, { display: "none", delay: 1 });
      TweenMax.to(".ya-share", 1, { display: "block", delay: 1, opacity: 1 });
    });
  }


  $(document).on('click', '.pepsico-buybtn', (e)=>{
    console.log(" categ: BuyButton, action: ClickBuy, label: "+document.location.pathname.split("/")[2]+", context: "+currentPack);
    dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'BuyButton',
      eventAction: 'ClickBuy',
      eventLabel: document.location.pathname.split("/")[2]
//      eventLabel: document.location.pathname.split("/")[2],
//      eventContext: currentPack
    });
  });

  $(document).on('click', '.juice__locker', (e)=>{
    dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'Keyhole',
      eventAction: 'ClickKeyhole',
      eventLabel: document.location.pathname.split("/")[2]
    });
  });


  let itms = document.querySelectorAll('.retailer-slider__name');

  itms.forEach((i)=>{
    itms[i].addEventListener('click', (e)=>{
      console.log(1)
    })
  });


  let inputs = document.querySelectorAll('.form__input input[required]');

  inputs.forEach(input=>{
    input.addEventListener('blur', e=>{
      let t = e.target;
      if(t.id.indexOf('email')<0){
        if(!t.value){
          t.classList.add('invalid')
        }
        else{
          t.classList.remove('invalid')
        }
      }
      else{
        let reg = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i;
        if(reg.test(t.value)){
          t.classList.remove('invalid')
        }
        else{
          t.classList.add('invalid')
        }
      }
    });
    input.addEventListener('input', e=>{
      let t = e.target;
      if(e.target.classList.contains('invalid')){
        if(t.id.indexOf('email')<0){
          if(!t.value){
            t.classList.add('invalid')
          }
          else{
            t.classList.remove('invalid')
          }
        }
        else{
          let reg = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i;
          if(reg.test(t.value)){
            t.classList.remove('invalid')
          }
          else{
            t.classList.add('invalid')
          }
        }
      }
    });
  });

  /*if((document.documentMode || /Edge/.test(navigator.userAgent))){
    let s = document.createElement('script');

    s.src = '/jslibs/object-fit.js';
    s.type = 'text/javascript';

    document.querySelector('body').appendChild(s);
  }*/

  var lazyloads2 = $('.lazyload');
  for (let i = 0; i < lazyloads2.length; i += 1) {
    var lazyImg2 = lazyloads2[i];
    if(lazyloads2[i].src.includes('//:0') || !lazyloads2[i].src.length) {
        if (lazyImg2.dataset.src) lazyImg2.src = lazyImg2.dataset.src;
        if (lazyImg2.dataset.srcset) lazyImg2.srcset = lazyImg2.dataset.srcset;
    }
  }
};



window.addEventListener('resize', ()=>{
  setNeedestAngle();
  vh();
})

let momentVideos = document.querySelectorAll('.momentVideo');

momentVideos.forEach(item=>{

  let src = !device.mobile() ? momentsVideo[getJuice()] : momentsMobileVideo[getJuice()];

  item.src = src; 


  item.addEventListener('canplaythrough', e=>{
    item.classList.remove('notVisible');
    item.play();
  });
  item.addEventListener('error', e=>{
    item.pause();
    item.classList.add('notVisible');
  })
})