const thumbsLinks = document.querySelectorAll(".thumbs__item");
for (let i = 0; i < thumbsLinks.length; i += 1) {
  thumbsLinks[i].addEventListener(
	"click",
	event => {
	  if(event.currentTarget.getAttribute('data-title')) {
		  document.title = event.currentTarget.getAttribute('data-title');
	  }
	  if(event.currentTarget.getAttribute('data-description')) {
		  var metaList = document.getElementsByTagName("meta");
		  for (var i = 0; i < metaList.length; i++) {
			if (metaList[i].getAttribute("name") == "description") {
				metaList[i].content = event.currentTarget.getAttribute('data-description');
			}
		  }
	  } else {
		  var metaList = document.getElementsByTagName("meta");
		  for (var i = 0; i < metaList.length; i++) {
			if (metaList[i].getAttribute("name") == "description") {
				metaList[i].content = "";
			}
		  }
	  }
	},
	false
  );
}